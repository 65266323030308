import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BigNumber, Contract, providers } from 'ethers';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { getConfig } from '../constants';
import { AUCTION_HOUSE_ABI } from '../abis';
import { AuctionHouse } from '../typechain';
import { getTokenBids, getTokenInfo } from './TokenSlice';
import { Auction } from '../models/tokenInfo';

export interface AuctionSliceState {
  currentAuction?: Auction;
  minBidIncrementPercentage?: number;
  bidding: boolean;
  price?: BigNumber;
}

export const getMinBidIncrementPercentage = createAsyncThunk(
  'auction/getMinBidIncrementPercentage',
  async ({ chainId, provider }: { chainId: number; provider: providers.JsonRpcProvider }) => {
    const { auctionHouseAddress } = getConfig(chainId);
    const auctionHouseContract = new Contract(auctionHouseAddress, AUCTION_HOUSE_ABI, provider) as AuctionHouse;
    return auctionHouseContract.minBidIncrementPercentage();
  },
);

export const getReservePrice = createAsyncThunk(
  'auction/getReservePrice',
  async ({ chainId, provider }: { chainId: number; provider: providers.JsonRpcProvider }) => {
    const { auctionHouseAddress } = getConfig(chainId);
    const auctionHouseContract = new Contract(auctionHouseAddress, AUCTION_HOUSE_ABI, provider) as AuctionHouse;
    return auctionHouseContract.reservePrice();
  },
);

export const createBid = createAsyncThunk(
  'auction/createBid',
  async ({
    chainId,
    provider,
    amount,
    nftId,
  }: {
    nftId: BigNumber;
    chainId: number;
    amount: BigNumber;
    provider: providers.JsonRpcProvider;
  }) => {
    const { auctionHouseAddress } = getConfig(chainId);
    const auctionHouseContract = new Contract(
      auctionHouseAddress,
      AUCTION_HOUSE_ABI,
      provider.getSigner(),
    ) as AuctionHouse;

    const transaction = await auctionHouseContract.createBid(nftId, { value: amount });
    await transaction.wait();
  },
);

export const getAuction = createAsyncThunk(
  'auction/getAuction',
  async (
    { chainId, provider }: { chainId: number; provider: providers.JsonRpcProvider },
    { dispatch },
  ): Promise<Auction> => {
    const { auctionHouseAddress } = getConfig(chainId);
    const auctionHouseContract = new Contract(auctionHouseAddress, AUCTION_HOUSE_ABI, provider) as AuctionHouse;
    const auction = await auctionHouseContract.auction();
    const [tokenInfo, tokenBids] = await Promise.all([
      dispatch(
        getTokenInfo({
          chainId,
          provider,
          tokenId: auction.nftId,
        }),
      ).unwrap(),
      dispatch(getTokenBids({ tokenId: auction.nftId })).unwrap(),
    ]);
    return {
      startTime: auction.startTime.toNumber(),
      endTime: auction.endTime.toNumber(),
      amount: auction.amount,
      bidder: auction.bidder,
      settled: auction.settled,
      nft: tokenInfo,
      bids: tokenBids,
    };
  },
);

export const auctionSlice = createSlice<AuctionSliceState, SliceCaseReducers<AuctionSliceState>>({
  name: 'auction',
  initialState: {
    bidding: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuction.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAuction.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          currentAuction: payload,
        };
      })
      .addCase(getAuction.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getMinBidIncrementPercentage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          minBidIncrementPercentage: payload,
        };
      })
      .addCase(createBid.pending, (state) => {
        return {
          ...state,
          bidding: true,
        };
      })
      .addCase(createBid.fulfilled, (state) => {
        return {
          ...state,
          bidding: false,
        };
      })
      .addCase(createBid.rejected, (state) => {
        return {
          ...state,
          bidding: false,
        };
      })
      .addCase(getReservePrice.fulfilled, (state, { payload }) => {
        return {
          ...state,
          price: payload,
        };
      });
  },
});

export const auctionReducer = auctionSlice.reducer;
