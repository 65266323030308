import { BigNumber } from 'ethers';

export enum ChainId {
  Mainnet = 1,
  Testnet = 5,
}

export function minutesAgo(x: number): number {
  const now = new Date().getTime();
  return new Date(now - x * 60000).getTime();
}

export function minOfBigNumber(...args: Array<BigNumber>): BigNumber {
  if (args.length === 0) {
    throw new Error();
  }

  return args.reduce((acc, cur) => {
    return acc.lt(cur) ? acc : cur;
  });
}

export function maxOfBigNumber(...args: Array<BigNumber>): BigNumber {
  if (args.length === 0) {
    throw new Error();
  }

  return args.reduce((acc, cur) => {
    return acc.gt(cur) ? acc : cur;
  });
}

export function trim(number: string | number = 0, precision = 0): string {
  // why would number ever be undefined??? what are we trimming?
  const array = number.toString().split('.');

  if (array.length === 1) return number.toString();
  if (precision === 0) return array[0].toString();

  const poppedNumber = array.pop() || '0';
  array.push(poppedNumber.substring(0, precision));

  return array.join('.');
}
