import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BigNumber, providers } from 'ethers';
import { nftTokenNames, nftTokenURIs } from '../constants';
import { BidHistory, TokenInfo } from '../models/tokenInfo';
import apollo from '../libs/apolloClient';

export const getTokenBids = createAsyncThunk(
  'token/getTokenBids',
  async ({ tokenId }: { tokenId: BigNumber }): Promise<BidHistory[]> => {
    const tokenBidsQuery = `
      query {
        bids(first: 3, where: { nftId: "${tokenId.toString()}" }, orderBy: amount, orderDirection: desc) {
          id
          amount
          bidder {
            id
          }
        }
      }
    `;

    const response = await apollo<{ bids: (BidHistory & { amount: string })[] }>(tokenBidsQuery);

    if (!response) {
      return [];
    }

    return response.data.bids.map((history) => {
      return {
        ...history,
        amount: BigNumber.from(history.amount),
      };
    });
  },
);

export const getTokenInfo = createAsyncThunk(
  'token/getTokenInfo',
  async ({
    tokenId,
  }: {
    tokenId: BigNumber;
    chainId: number;
    provider: providers.JsonRpcProvider;
  }): Promise<TokenInfo> => {
    // const { nftAddress } = getConfig(chainId);
    // const nftContract = new Contract(nftAddress, ERC721_ABI, provider) as ERC721;

    // const tokenURI = await nftContract.tokenURI(tokenId);
    // const name = await nftContract.name();

    return {
      tokenId,
      name: nftTokenNames[tokenId.toNumber()],
      tokenURI: nftTokenURIs[tokenId.toNumber()],
    };
  },
);

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {},
  reducers: {},
});

export const tokenReducer = tokenSlice.reducer;
