import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#67B8F1',
      dark: '#14539D',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFCF58',
      contrastText: '#000',
    },
    text: {
      primary: '#1B1E28',
    },
  },
  typography: {
    fontFamily: '"Noto Sans", Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '4rem',
    },
    h3: {
      fontSize: '2.5rem',
    },
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1248,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        img: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'unset',
          fontWeight: 400,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
  },
});
