import React, { lazy } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import { defaultTheme } from './themes';
import Footer from './components/Footer/Footer';

const Home = lazy(() => import('./pages/Home/Home'));

function App(): JSX.Element {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={null}>
              <Home />
            </React.Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
