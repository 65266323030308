import { configureStore } from '@reduxjs/toolkit';
import { auctionReducer } from './slices/AuctionSlice';
import { tokenReducer } from './slices/TokenSlice';

const store = configureStore({
  reducer: {
    auction: auctionReducer,
    nft: tokenReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
