import { ChainId } from './helpers';

export const THE_GRAPH_URL = 'https://api.thegraph.com/subgraphs/name/congruentfi/auction';
export const CONGRUENT_THE_GRAPH_URL = 'https://api.thegraph.com/subgraphs/name/congruentfi/congruent';
const NFT_ENDPOINT = 'https://cdn.congruent.fi';

export const TREASURY_ADDRESSES: string[] = [
  '0x4a6bf7737b54195BFB72030d8F2bf7Cf2b466dC3',
  '0x6E24F6E66A6c9c86cF4c5C60B7bcE7c73BfA6666',
  '0x73141d278a9c71d2ef2a0b83565e9d5728fa15cb',
];

export interface Config {
  auctionHouseAddress: string;
  nftAddress: string;
  treasury: string;
}

export const defaultChainId = ChainId.Mainnet;

export const networkConfig: Record<number, Config> = {
  [Number(ChainId.Mainnet)]: {
    auctionHouseAddress: '0x56f090427DfDC6e40596daa3b0A97CD74216984C',
    nftAddress: '0x4Ffc9a4947877a9098736352b3964FfF0e1F7F67',
    treasury: '0xe70311b73a193b9f87653c9399dd04c605d3e357',
  },
};

export function getConfig(chainId: number): Config {
  let config = networkConfig[chainId];

  if (config === undefined) {
    config = networkConfig[defaultChainId];
  }
  if (config === undefined) {
    throw Error(`Please set config`);
  }

  return config;
}

export const nftTokenNames: string[] = [
  'The Awaken',
  'The Space oddity',
  'Childhood Memories',
  'Self Reflection',
  'Polished Dream',
  'Mind mapping',
  'Transparent museum',
  'Form compression',
  'Foundation',
  'Lucid stack',
  'Non fudable token',
  'The sheen ',
  'Motional emotion',
  'Blue collection',
  'The ancient altar',
  'The swirl',
  'Life in vases',
  'The Bodhi',
  'The rewind',
  'The lucidity',
  'The spine',
  'The cave',
  'Stained glasses',
  'Soft core, universe',
  'Fireworks, inside boxes',
  'The aperture',
  'Sun, solar, system',
  'Continue listening',
  'The bloom contour',
  'The red envelope',
  'The lost clip',
  'Eternal Tangerine',
  'Nature as code',
  'The Telescope',
  'Reflection of Life',
  'The Florescence',
  'The Beautyfly',
  'Treasure Hunt',
  'Interstellar',
  'Colonization, Tomorrow',
  'DAO, governance, people',
];

export const nftTokenURIs: string[] = [
  `${NFT_ENDPOINT}/0.mp4`,
  `${NFT_ENDPOINT}/1.mp4`,
  `${NFT_ENDPOINT}/2.mp4`,
  `${NFT_ENDPOINT}/3.mp4`,
  `${NFT_ENDPOINT}/4.mp4`,
  `${NFT_ENDPOINT}/5.png`,
  `${NFT_ENDPOINT}/6.png`,
  `${NFT_ENDPOINT}/7.png`,
  `${NFT_ENDPOINT}/8.png`,
  `${NFT_ENDPOINT}/9.png`,
  `${NFT_ENDPOINT}/10.mp4`,
  `${NFT_ENDPOINT}/11.mp4`,
  `${NFT_ENDPOINT}/12.mp4`,
  `${NFT_ENDPOINT}/13.jpg`,
  `${NFT_ENDPOINT}/14.jpg`,
  `${NFT_ENDPOINT}/15.jpg`,
  `${NFT_ENDPOINT}/16.jpg`,
  `${NFT_ENDPOINT}/17.jpg`,
  `${NFT_ENDPOINT}/18.jpg`,
  `${NFT_ENDPOINT}/19.jpg`,
  `${NFT_ENDPOINT}/20.jpg`,
  `${NFT_ENDPOINT}/21.jpg`,
  `${NFT_ENDPOINT}/22.png`,
  `${NFT_ENDPOINT}/23.png`,
  `${NFT_ENDPOINT}/24.jpg`,
  `${NFT_ENDPOINT}/25.jpg`,
  `${NFT_ENDPOINT}/26.jpg`,
  `${NFT_ENDPOINT}/27.jpg`,
  `${NFT_ENDPOINT}/28.jpg`,
  `${NFT_ENDPOINT}/29.jpg`,
  `${NFT_ENDPOINT}/30.jpg`,
  `${NFT_ENDPOINT}/31.jpg`,
  `${NFT_ENDPOINT}/32.jpg`,
  `${NFT_ENDPOINT}/33.jpg`,
  `${NFT_ENDPOINT}/34.jpg`,
  `${NFT_ENDPOINT}/35.jpg`,
  `${NFT_ENDPOINT}/36.jpg`,
  `${NFT_ENDPOINT}/37.jpg`,
  `${NFT_ENDPOINT}/38.jpg`,
  `${NFT_ENDPOINT}/39.jpg`,
  `${NFT_ENDPOINT}/40.jpg`,
];

export const TWITTER_LINK = 'https://twitter.com/CongruentFi';
export const DISCORD_LINK = 'https://discord.gg/congruentfi';
export const DOCUMENT_LINK = 'https://congruent.gitbook.io/';
