import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

import Logo from '../../assets/images/header-logo.png';
import { DISCORD_LINK, DOCUMENT_LINK, TWITTER_LINK } from '../../constants';

export default function Footer(): JSX.Element {
  return (
    <Box sx={{ background: '#282B32', py: 8.5 }}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#EBEDF0',
                width: 115,
                height: 115,
                borderRadius: '50%',
                mb: 1.25,
              }}
            >
              <Box component="img" alt="" src={Logo} sx={{ width: 72, height: 56 }} />
            </Box>
            <Typography sx={{ textAlign: 'center', fontSize: '1.3125rem' }} color="#fff">
              <strong>Congruent</strong>
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
            <Typography
              variant="h4"
              sx={{ fontSize: '1.3125rem', lineHeight: 1.33, maxWidth: 345, '& span': { color: 'primary.main' } }}
              color="#fff"
            >
              <strong>
                Congruent governance
                <br />
                for <span>people</span>, <span>protocols</span>, and <span>tokens</span>!
              </strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: '1.125rem', color: 'primary.main', mb: 2.5 }}>
              <strong>Community</strong>
            </Typography>
            <Grid container flexDirection="column" spacing={2} sx={{ color: '#fff' }}>
              <Grid item>
                <Link href={TWITTER_LINK} target="_blank" color="#fff" underline="none">
                  <Typography variant="body2">Twitter</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href={DISCORD_LINK} target="_blank" color="#fff" underline="none">
                  <Typography variant="body2">Discord</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://t.me/CongruentFiAnn" target="_blank" color="#fff" underline="none">
                  <Typography variant="body2">Telegram Announcements</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: '1.125rem', color: 'primary.main', mb: 2.5 }}>
              <strong>Resources</strong>
            </Typography>
            <Grid container flexDirection="column" spacing={2} sx={{ color: '#fff' }}>
              <Grid item>
                <Link href={DOCUMENT_LINK} color="#fff" underline="none" target="_blank">
                  <Typography variant="body2">Documentation</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href="https://medium.com/@CongruentFi" target="_blank" color="#fff" underline="none">
                  <Typography variant="body2">Medium</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography variant="body2">Contact@congruent.fi</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
