import { providers } from 'ethers';
import Web3Modal from 'web3modal';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { IFrameEthereumProvider } from '@ledgerhq/iframe-provider';
import { ChainId } from '../helpers';
import { defaultChainId } from '../constants';

function isIframe(): boolean {
  return window.location !== window.parent.location;
}

type OnChainProvider = {
  connect: () => Promise<providers.Web3Provider | undefined>;
  disconnect: () => void;
  hasCachedProvider: () => boolean;
  address: string;
  connected: boolean;
  provider: providers.JsonRpcProvider;
  web3Modal: Web3Modal;
  chainId: number;
  wrongNetwork: boolean;
};

export type Web3ContextData = {
  onChainProvider: OnChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = (): OnChainProvider => {
  const web3Context = useContext(Web3Context);

  if (!web3Context) {
    throw new Error(
      'useWeb3Context() can only be used inside of <Web3ContextProvider />, please declare it at a higher level.',
    );
  }
  const { onChainProvider } = web3Context;

  return useMemo<OnChainProvider>(() => {
    return { ...onChainProvider };
  }, [onChainProvider]);
};

export const useAddress = (): string => {
  const { address } = useWeb3Context();
  return address;
};

const initModal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    // walletconnect: {
    //   package: WalletConnectProvider,
    //   options: {
    //     rpc: {},
    //   },
    // },
  },
});

export function Web3ContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [connected, setConnected] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  // NOTE (appleseed): loading eth mainnet as default rpc provider for a non-connected wallet
  const [provider, setProvider] = useState<providers.JsonRpcProvider>(
    new providers.StaticJsonRpcProvider('https://mainnet-eth.compound.finance/', defaultChainId),
  );
  const [chainId, setChainId] = useState<number>(defaultChainId);
  const [web3Modal] = useState<Web3Modal>(initModal);
  const [wrongNetwork, setWrongNetwork] = useState<boolean>(false);

  const hasCachedProvider = useCallback((): boolean => {
    return !!web3Modal.cachedProvider;
  }, [web3Modal]);

  // NOTE (appleseed): none of these listeners are needed for Backend API Providers
  // ... so I changed these listeners so that they only apply to walletProviders, eliminating
  // ... polling to the backend providers for network changes
  const initListeners = useCallback((rawProvider): void => {
    if (!rawProvider.on) {
      return;
    }
    rawProvider.on('accountsChanged', async () => {
      setTimeout(() => window.location.reload(), 1);
    });

    rawProvider.on('chainChanged', async () => {
      setTimeout(() => window.location.reload(), 1);
    });
  }, []);

  // connect - only runs for WalletProviders
  const connect = useCallback(async () => {
    // handling Ledger Live;
    let rawProvider;
    if (isIframe()) {
      rawProvider = new IFrameEthereumProvider();
    } else {
      rawProvider = await web3Modal.connect();
    }

    initListeners(rawProvider);

    const connectedProvider = new providers.Web3Provider(rawProvider, 'any');
    const [connectedAddress, connectedNetwork] = await Promise.all([
      connectedProvider.getSigner().getAddress(),
      connectedProvider.getNetwork(),
    ]);

    // Save everything after we've validated the right network.
    // Eventually we'll be fine without doing network validations.
    setProvider(connectedProvider);
    setAddress(connectedAddress);
    setChainId(connectedNetwork.chainId);
    setWrongNetwork(![ChainId.Mainnet].includes(connectedNetwork.chainId));

    // Keep this at the bottom of the method, to ensure any repaints have the data we need
    setConnected(true);

    return connectedProvider;
  }, [web3Modal, initListeners]);

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setConnected(false);

    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [web3Modal]);

  const onChainProvider = useMemo(
    () => ({
      onChainProvider: {
        connect,
        disconnect,
        hasCachedProvider,
        provider,
        connected,
        address,
        web3Modal,
        chainId,
        wrongNetwork,
      },
    }),
    [connect, disconnect, hasCachedProvider, provider, connected, address, web3Modal, chainId, wrongNetwork],
  );

  return <Web3Context.Provider value={onChainProvider}>{children}</Web3Context.Provider>;
}
