import { ApolloClient, InMemoryCache, gql, ApolloQueryResult, NormalizedCacheObject } from '@apollo/client';
import { CONGRUENT_THE_GRAPH_URL, THE_GRAPH_URL } from '../constants';

function client(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: THE_GRAPH_URL,
    cache: new InMemoryCache(),
  });
}

function congruentClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: CONGRUENT_THE_GRAPH_URL,
    cache: new InMemoryCache(),
  });
}

export function congruentApollo<T>(queryString: string): Promise<ApolloQueryResult<T> | undefined> {
  return congruentClient()
    .query<T>({
      query: gql(queryString),
    })
    .catch(() => undefined);
}

async function apollo<T>(queryString: string): Promise<ApolloQueryResult<T> | undefined> {
  try {
    return client().query<T>({
      query: gql(queryString),
    });
  } catch (err) {
    return undefined;
  }
}

function extClient(uri: string): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

async function apolloExt<T>(queryString: string, uri: string): Promise<ApolloQueryResult<T> | undefined> {
  try {
    return await extClient(uri).query({
      query: gql(queryString),
    });
  } catch (err) {
    return undefined;
  }
}

export default apollo;
